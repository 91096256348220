<template>
  <div class="container">
    <div v-html="msg"></div>
  </div>
</template>

<script>
export default {
  name: 'ToastMsg',
  props: {
    msg: ""
  },
    data () {
        return {
        msg: ''
        }
    },
    methods: {
        showMsg: function () {
            this.msg = ''
        }
    }
}
</script>