<template>
  <div>
    <h2>{{ ('Deals List')  }}</h2>
    <div class="d-flex col page-actions" style="justify-content: right">
      <v-btn
      v-if="this.Has_Access('Auction','create')"
        @click="addnew"
        variant="outlined"
        style="
          background-color: #0089ff !important;
          color: white !important;
          margin-right: 1rem !important;
        "
      >
        {{("New Deal")}}</v-btn
      >
    </div>

    <hr style="margin-top: 1rem !important; margin-bottom: 1rem !important" />
    <div class="form-column col-sm-6">
            <v-autocomplete
              v-model="select_workflow"
              :items="stat_workflow"
              :label="name_status"
              item-text="state"
              item-value="state"
              outlined
              @change="get_status_workflow($event)"
              :disabled="is_disabled"
            >
            </v-autocomplete>
    </div>
    <div v-for="(group, workflowState) in groupedAuctions" :key="workflowState">
      <h3>{{ (workflowState) }}</h3>
      <ul style="display: flex; flex-wrap: wrap; list-style: none">
        <li
          v-for="auction in group"
          :key="auction.name"
          @click="openAuction(auction)"
          class="auction-item"
        >
          <div>
            <strong>{{ ('name') }} : </strong> {{ auction.name }}
          </div>
          <div>
            <strong>{{ ('Supplier') }} : </strong> {{ auction.supplier_name }}
          </div>
          <div>
            <strong>{{ ('City') }} : </strong> {{ auction.city_name }}
          </div>
          <div>
            <strong>{{ ('Date') }} : </strong> {{ auction.date }}
          </div>
          <div>
            <strong>{{ ('Agent Name') }} : </strong> {{ auction.owner }}
          </div>

          
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import AuctionPage from './AuctionPage.vue';

  export default {
    data: function () {
      return {

        dialog: false,
        pos_profile: 'Syarah',
        groupedAuctions: {},
        stat_workflow: [],
        name_status: 'Status',
        select_workflow: null,

      };
    },
    components: {
      AuctionPage


    },
    methods: {
      addnew(){

        this.$router.push({ name: 'AuctionPage', params :{auction_id:'new-auction'}  });
      },
      get_pos_setting() {

      },
      getAuctions(filters) {
      let creator = this.Has_Access( 'Auction', 'if_owner')
      console.log("creator",creator);
      if (creator) {
        creator = 1
      }
      else {
        creator = 0;
      }
      const url = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_auction?sid=${this.$store.state.SID}&lang=${this.$store.state.LANGUAGE}&filters=${filters}&creator=${creator}`;

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
            // console.log("data",data);
            const auctions = data.message;
            // console.log(auctions);
            // this.all_auctions = auctions;
            this.groupAuctionsByWorkflowState(auctions);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    },


    getworkflow() {

      const url = `${this.$store.state.DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.getworkflow?sid=${this.$store.state.SID}`;

      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
            // console.log("dataaaaaaa",data.message);
            this.stat_workflow = data.message;
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });

    },
    // Has_Access(doc,type){
    //   return this.$store.state.DOCTYPE_PERMISSIONS[doc][type]==1 ? true:false
    // },

    Has_Access( doctype, permissionType) {
      const doctypePermissions = this.$store.state.DOCTYPE_PERMISSIONS
    if (doctypePermissions.hasOwnProperty(doctype)) {
            return doctypePermissions[doctype].some(permission => permission[permissionType] === 1);
        }
        return false;
    },

    groupAuctionsByWorkflowState(auctions) {
      const groupedAuctions = {};

      auctions.forEach((auction) => {
        const workflowState = auction.workflow_state;
        // console.log("groupedAuctions",workflowState);
        // console.log("auction",auction);
        // this.stat_workflow.push(workflowState);
        if (!groupedAuctions[workflowState]) {
          groupedAuctions[workflowState] = [];
        }
        groupedAuctions[workflowState].push(auction);
      });

      this.groupedAuctions = groupedAuctions;
      console.log("groupedAuctions1111",groupedAuctions);
    },

    get_status_workflow(stat) {
      // console.log("stat",stat);
      this.getAuctions(stat) 
  },

openAuction(auction) {
if(this.Has_Access( 'Auction', 'read')) {
  this.$router.push({ name: 'AuctionPage', params :{auction_id:auction.name}  });
}
},

    },
    mounted() {
      this.getworkflow();
  if (this.Has_Access( 'Auction', 'select')){

          this.ispermission=true
          this.getAuctions();
        }
        else{

          this.ispermission=false


      }

    },
    created: function () {
      this.$nextTick(function () {
        this.get_pos_setting();


      });
    },
  };
</script>

<style scoped>
.auction-item {
  flex: 0 0 calc(33.33% - 10px);
  margin: 5px;
  border: 1px solid #131212;
  padding: 5px;
  border-radius: 20px;
  border-color: #0089ff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.auction-item:hover {
  background-color: #e0e0e0; /* Change the background color on hover */
}
</style>