// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const createStore = async (initialState = {}) => {
    const url = new URL(window.location.href);
    const sid_from_url = url.searchParams.get("sid")
    const lang_from_url = url.searchParams.get("lang")
    const user_from_url = url.searchParams.get("user")
    const csrf_token_from_url = url.searchParams.get("csrf_token")
    const main_site = url.searchParams.get("main_site") || initialState.DATA_DOMAIN

    if((!initialState.SID && !sid_from_url) || (initialState.ERP_USER && !user_from_url)) {
        let auth_url = main_site || window.location.origin.replace('fleet', 'erp');
        let container = document.createElement("div");
        container.style.cssText = "position: fixed; top: 0; left: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: #f0f0f0;";
        let messageDiv = document.createElement("div");
        messageDiv.style.cssText = "color:red;text-align: center; font-family: Arial, sans-serif; padding: 20px; border: 1px solid #ccc; background-color: #fff; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);";
        messageDiv.innerHTML = `<h1>Unauthorized Access</h1><p>You do not have permission to access this resource.</p><a href="${auth_url}/app/new-fleet-app">Authenticate</a>`;
        container.appendChild(messageDiv);
        document.body.innerHTML = '';
        document.body.appendChild(container);
        console.clear();
    }

    //Clean Query Params
    url.search = '';
    history.replaceState({}, '', url);
    console.log("User Role===>",user_from_url)
    const apiUrl = `${main_site}/api/method/syarahconnector.api_helper.vue_api.get_roles?user=${user_from_url}&sid=${sid_from_url}`;

    try {
        const response = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const data = await response.json();

        const userRoles = data.message && data.message[0] ? data.message[0] : [];
        const docTypePermissions = data.message && data.message[1] ? data.message[1] : [];

        return new Vuex.Store({
            state: {
                SID: sid_from_url || '',
                CSRF_TOKEN: csrf_token_from_url || '',
                DATA_DOMAIN: main_site || '',
                LANGUAGE: lang_from_url || '',
                USER_ROLES: userRoles || [],
                DOCTYPE_PERMISSIONS: docTypePermissions,
                ERP_USER: user_from_url || '',
                refreshKey: 0,
                ...initialState,
            },
            mutations: {
                setSID(state, SID) {
                    state.SID = SID;
                },
                incrementRefreshKey(state) {
                    state.refreshKey += 1;
                },
            },
        });
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error; // Rethrow the error to indicate the failure to create the store
    }
};

export default createStore;