<template>
  <v-dialog v-model="dialog" persistent width="auto">
    <v-card>
      <v-card-title class="headline">Confirm Action</v-card-title>
      <v-card-text>
        There are still cars to be inspected, do you want to set them to "Did
        Not Inspect"?
      </v-card-text>
      <v-card-actions>
        <v-btn @click="cancel">No</v-btn>
        <v-btn @click="confirm" color="primary">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
    };
  },
  methods: {
    cancel() {
      this.dialog = false;
      this.reject('canceled');
    },
    confirm() {
      this.dialog = false;
      this.resolve(true);
    },
  },
  watch: {
    dialog(newVal) {
      if (!newVal && this.reject) {
        this.reject('closed');
      }
    },
  },
};
</script>