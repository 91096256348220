// main.js
import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify'
import router from './router';
import createStore from './store';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

let store

Vue.config.productionTip = false // Remove This For Prod Build To Check Warnings
Vue.config.silent = true // Remove This For Prod Build To Check Warnings
Vue.use(VueRouter);
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true
});

(async () => {
  if (!sessionStorage.getItem("store_loaded")) {
    store = await createStore();
    sessionStorage.setItem("store_loaded", true);
    sessionStorage.setItem("vue_store", JSON.stringify(store.state));
    console.log('Store Initialized', store)
  }
  else {
    let storedState = JSON.parse(sessionStorage.getItem("vue_store"))
    store = await createStore(storedState);
    console.log('Store Reloaded From Session Storage', store)
  }

  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-K64WJ7KX');

  console.log('Mix Panel Loaded MBF')

  let apiUrl = `${JSON.parse(sessionStorage.vue_store).DATA_DOMAIN}/api/method/syarahconnector.api_helper.vue_api.get_user_info?sid=${JSON.parse(sessionStorage.vue_store).SID}&user=${JSON.parse(sessionStorage.vue_store).ERP_USER}`;
fetch(apiUrl, {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json'
    },
})
    .then(response => response.json())
    .then(data => {
      console.log(data)
        let role_profile_name = data.message.role_profile_name;
        let id = data.message.name;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "user_id": id,
            "user_role": role_profile_name
        })
        console.log('Role Loaded MBF user_id',id)
        console.log('Role Loaded MBF user_role',role_profile_name)
        console.log('Role Loaded MBF')
    })


  new Vue({
    vuetify,
    render: h => h(App),
    router,
    store,
  }).$mount('#app');
})();