<template>
  <v-app class="container1">
    <v-toolbar-title
      class="text-uppercase grey--text"
      style="margin-right: 55px; margin-left: 55px; display: inline-block"
    >
      <span class="font-weight-light">{{ ('FLEET USED CARS SOURCING') }}</span><span class="logged_in_user"><v-icon>mdi-account</v-icon> - {{this.$store.state.ERP_USER}}</span>
    </v-toolbar-title>

    <v-navigation-drawer
      app
      :right="isArabic"
      color="#0089ff"
      width="50px"
      :mobile-breakpoint="0"
    >
      <v-list>
        <v-list-item
          @click="refreshPage"
          title="Reload"
          @contextmenu.prevent="showHardReloadButton = !showHardReloadButton"
        >
          <v-list-item-icon>
            <v-icon style="color: white">mdi-refresh</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Hover over me</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="showHardReloadButton"
          class="hard-reload-button"
          @click="hardReload"
          title="Hard Reload"
          @contextmenu.prevent="showHardReloadButton = !showHardReloadButton"
        >
          <v-list-item-icon>
            <v-icon style="color: white">mdi-cached</v-icon>
          </v-list-item-icon>
          <v-list-item-title></v-list-item-title>
        </v-list-item>

        <v-list-item @click="goBack" title="Back to main page">
          <v-list-item-icon>
            <v-icon
              style="color: white"
              v-if="page !== 'DealsPage'"
              >{{ arrowIcon }}</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title></v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="showHardReloadButton"
          class="hard-reload-button"
          @click="hardReload"
          title="Hard Reload"
          @contextmenu.prevent="showHardReloadButton = !showHardReloadButton"
        >
          <v-list-item-icon>
            <v-icon style="color: white">mdi-cached</v-icon>
          </v-list-item-icon>
          <v-list-item-title></v-list-item-title>
        </v-list-item>

        <v-list-item class="latest-page-button" @click="openErpNext">
          <v-list-item-icon>
            <v-icon
              style="color: white; font-family: unset"
              >{{ erpIcon }}</v-icon
            >
          </v-list-item-icon>
          <v-list-item-title></v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main :key="$store.state.refreshKey">
      <keep-alive>
        <router-view :key="$route.meta.key"></router-view>
      </keep-alive>
    </v-main>
  </v-app>
</template>

<script>
import AuctionPage from './components/pos/AuctionPage.vue';
import DealsPage from './components/pos/DealsPage.vue';
export default {
  props: ["ispermission"],
  data: function () {
    return {
      isArabic:false,
      refreshComponent: true,
      ispermission:false,
      showName: false,
      page: '',
      showHardReloadButton: false
    };
  },
  computed: {

    refreshKey() {
      return this.$store.state.refreshKey;
    },


    arrowIcon() {

     return this.isArabic ? 'mdi-arrow-right' : 'mdi-arrow-left';
   },erpIcon() {

return 'mdi-alpha-e-box';
},
  },
  components: {
    AuctionPage,
    DealsPage,
  },

  methods: {

    setPage(page) {
      this.page = page;
    },
    goBack() {
      this.$router.push({ name: 'App' })
    },
     refreshPage() {
      this.$store.commit('incrementRefreshKey');
    },
    async hardReload() {
      await new Promise((resolve) => {
        this.$toast.info("Clearing Cache...", {
          position: "bottom-right",
          timeout: 1000,
          onClose: resolve
        });
      });
      sessionStorage.clear();
      localStorage.clear();
      if ('serviceWorker' in navigator) {
        await navigator.serviceWorker.getRegistrations()
          .then(async function (registrations) {
            for (let registration of registrations) {
              await registration.unregister();
            }
          });
      }
      window.location.reload(true);
      const newUrl = `${this.$store.state.DATA_DOMAIN}/app/new-fleet-app`
      console.log('NEW URL',newUrl)
      window.location.href = newUrl;
    },
    openErpNext() {

      window.location.href = this.$store.state.DATA_DOMAIN
    }

  },

   mounted() {
    if(!this.$store.state.SID) {
      let auth_url = this.$store.state.main_site || window.location.origin.replace('fleet', 'erp');
      let container = document.createElement("div");
      container.style.cssText = "position: fixed; top: 0; left: 0; width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; background-color: #f0f0f0;";
      let messageDiv = document.createElement("div");
      messageDiv.style.cssText = "color:red;text-align: center; font-family: Arial, sans-serif; padding: 20px; border: 1px solid #ccc; background-color: #fff; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);";
      messageDiv.innerHTML = `<h1>Unauthorized Access</h1><p>You do not have permission to access this resource.</p><a href="${auth_url}/app/new-fleet-app">Authenticate</a>`;
      container.appendChild(messageDiv);
      document.body.innerHTML = '';
      document.body.appendChild(container);
      console.clear();


    }
    else {
      this.$toast.success("Authenticated!", { position: "bottom-right", timeout: 1000})
    }
    const langAttribute = document.documentElement.getAttribute('lang');
  if (langAttribute === 'ar') {
    this.isArabic = true;
  } else {
    this.isArabic = false;
  }

        if (this.$store.state.USER_ROLES.includes('Fleet Used Cars Sourcing')){
          this.ispermission=true



        }
        else{
          this.ispermission=true


      }

  },

  updated() {},
  created: function () {

    setTimeout(() => {
    }, 1000);
  },
};
</script>

<style scoped>
.container1 {
  margin: 10px;
}
.latest-page-button {
  position: fixed;
  bottom: 20px;
}

.hard-reload-button {
  position: absolute;
  top: 8px;
  background: #0089ff;
}
.form-group {
  margin-left: 5px;
}
.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
}
.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 1px !important;
}
.content-container {
  display: flex;
  transition: transform 1s;
}
.move-right {
  transform: translateX(50px);
}

.hover-item .v-list-item-title::before {
  content: "Static Title";
  display: none;
}

.hover-item:hover .v-list-item-title::before {
  content: "Static Title";
  display: block;
}
.logged_in_user {
  float: right;
  display: flex;
  text-transform: none;
}
</style>