// router.js
import Vue from 'vue';
import VueRouter from 'vue-router';
import DealsPage from './components/pos/DealsPage.vue';
import AuctionPage from './components/pos/AuctionPage.vue';

Vue.use(VueRouter);

const routes = [{
        path: '/',
        name: 'App',
        component: DealsPage,
        props: (route) => ({
            isPermission: route.query.isPermission
        }),
        beforeEnter: (to, from, next) => {
            to.meta.key = Math.random().toString(36).substring(7);
            next();
        }
    },
    {
        path: '/AuctionPage/:auction_id',
        name: 'AuctionPage',
        component: AuctionPage,
        props: true,
        beforeEnter: (to, from, next) => {
            to.meta.key = to.params.auction_id;
            next();
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

export default router;